import React from 'react'
import { Heading, Link, Section } from 'src/components/Base'
import Seo from 'src/components/Seo'

import { cLink } from './not-found.module.scss'

const NotFoundPage = () => {
  let notFoundTitle = ''
  let notFoundText = <p></p>
  const isBrowser = typeof window !== 'undefined'

  if (isBrowser) {
    if (window.location.href.includes('/en/')) {
      notFoundTitle = 'Site not found!'
      notFoundText = (
        <>
          <p>The requested page no longer exists or could not be found.</p>
          <p>
            Please return to the{' '}
            <Link className={cLink} to="/en/">
              homepage
            </Link>{' '}
            or use the menu to get to another site.
          </p>
        </>
      )
    } else {
      notFoundTitle = 'Seite nicht gefunden'
      notFoundText = (
        <>
          <p>
            Die angeforderte Seite existiert nicht mehr oder wurde nicht
            gefunden.
          </p>
          <p>
            Bitte kehren Sie auf die{' '}
            <Link to="/" className={cLink}>
              Startseite
            </Link>{' '}
            zurück oder nutzen Sie das Menü um andere Seiten aufzurufen.
          </p>
        </>
      )
    }
  }

  return (
    <>
      <Seo title={notFoundTitle} />
      <Section>
        <Heading>{notFoundTitle}</Heading>
        {notFoundText}
      </Section>
    </>
  )
}

export default NotFoundPage
